require('../css/app.scss');

import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-fr';

var $ = require('jquery');
global.$ = global.jQuery = $;

//JS is equivalent to the normal "bootstrap" package
//no need to set this to a variable, just require it

$(document).ready(function() {
    nautispot.init();
   
    $(".required-star").after("<span class='required-star'>*</span>");
    $('.identique-client-checkbox').click(function(){
        if($('.identique-client-checkbox').prop('checked')) {
            $.each(client, function(index,value) {
                $('[name="stagiaire['+index+']"]').val([value]);
                $('#stagiaire_'+index).val(value);
            });
        } else {
            $.each(client, function(index,value) {
                $('[name="stagiaire['+index+']"]:checked').prop( "checked",false);
                $('#stagiaire_'+index).val('');
            });
        }
    });
    $('.choix-stagiaire').change( function(e) {
       nautispot.saveChoixStagiaire(this);
    });
    $('.choix-options').click( function(e) {
        nautispot.saveChoixOptions(this);
     });
    $('#verif-code').click( function(e) {
        e.preventDefault();
        nautispot.savePromotion();
     });
    nautispot.validationPanier();
    nautispot.smoothScroll();
    nautispot.backToTop();
});

var nautispot = function() {
    return {
        init : function() {
            nautispot.datepicker();
            nautispot.carousel();
            nautispot.checkboxSelect();
        },
        carousel : function() {
            $('.carousel').carousel("pause");    
            $('.carousel').mouseover(function() {
                $('.carousel').carousel("cycle");
            });
            $('.carousel').mouseout(function() {
                $('.carousel').carousel("pause");
            });            
        },
        checkboxSelect : function() {
            $('.select-all-checkboxes').click(function(){
                if($('.select-all-checkboxes').prop('checked')) {
                    $('.box-to-check').prop('checked',true);
                } else {
                    $('.box-to-check').prop('checked',false);
                }
                $('.box-to-check').click(function(){
                    $('.select-all-checkboxes').prop('checked',false);
                });

            });
        },
        datepicker : function() {
            datepickerFactory($);
            datepickerJAFactory($);
            var options = {
            };
            $('.datepicker').datepicker(options);
        },
        saveChoixStagiaire : function(element) {
            var idStagiaire = $(element).val();
            var idLignePanier = $(element).prop('name').split("[")[0];
            $.ajax({
              type: "POST",
              url: Routing.generate('ajax_enregistre_stagiaire'),
              data: { idStagiaire: idStagiaire, idLignePanier: idLignePanier },
              success: function (msg) {
                //$("#ajax-resultat").html(msg);
              }
            });
        },
        saveChoixOptions : function(element) {
            var optionVal = $(element).prop('checked');
            var optionName = $(element).attr('id').split('_');
            var idLignePanier = $(element).prop('name').split("[")[0];
            $.ajax({
              type: "POST",
              url: Routing.generate('ajax_enregistre_options'),
              data: { optionVal: optionVal, optionName: optionName[1], idLignePanier: idLignePanier },
              success: function (msg) {
                //$("#ajax-resultat").html(msg);
              }
            });
        },
        savePromotion : function(element) {
            var code = $('#code-promotion').val();
            $.ajax({
              type: "POST",
              url: Routing.generate('ajax_valide_promotion'),
              data: { code: code },
              success: function (jsonResult) {
                  var result = JSON.parse(jsonResult);
                  var msg = '';
                  if(result.result) {
                      msg = '<div class="promo-resultat bg-success mt-2">'+result.msg+'</div>';
                      $("#montant-total").html(result.total-result.promotion.total);
                      $("#montant-promotion").html(result.promotion.total);
                      $("#promotion-code").html(result.promotion.code);
                      $("#promotion").removeClass('hidden');
                  } else {
                      msg = '<div class="promo-resultat bg-danger mt-2">'+result.msg+'</div>';
                  }
                  $("#promotion-resultat").html(msg);
              }
            });
        },
        validationPanier : function() {
            if ($('#cgv').is(':checked')) {
                $('#recapitulatif-btn').removeAttr('disabled');
            }
            $('#cgv').click(function(){
                $('#recapitulatif-btn').attr('disabled','disabled');
                if($(this).prop('checked')) {
                    $('#recapitulatif-btn').removeAttr('disabled');
                }
            });
            $('#recapitulatif-btn').click(function(e) {
                if(!$('#cgv').prop('checked')) {
                    e.preventDefault();
                }
            });
        },
        showDetails : function(id) {
            /*if ($('#' + id).hasClass('hidden')) {
                $('#' + id).removeClass('hidden');
            } else {
                $('#' + id).addClass('hidden');
            }*/

            if($('.'+id).hasClass('hidden')) {
                $('.' + id).removeClass('hidden');
            } else {
                $('.' + id).addClass('hidden');
            }
        },
        smoothScroll : function() {
            $('.js-scrollTo').on('click', function() { // Au clic sur un élément
                var page = $(this).attr('href'); // Page cible
                var speed = 750; // Durée de l'animation (en ms)
                $('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
                return false;
            });
        },
        backToTop : function() {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 50) {
                    $('#back-to-top').fadeIn();
                } else {
                    $('#back-to-top').fadeOut();
                }
            });
            // scroll body to 0px on click
            $('#back-to-top').click(function () {
                $('#back-to-top').tooltip('hide');
                $('body,html').animate({
                    scrollTop: 0
                }, 800);
                return false;
            });

            $('#back-to-top').tooltip('show');
        }
    }
}();

global.nautispot = nautispot;
